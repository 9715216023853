import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { apiBaseUrl } from '../../util/api';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
    isSignupEmailTakenError,
    isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
    Page,
    NamedLink,
    NamedRedirect,
    LinkTabNavHorizontal,
    IconEmailSent,
    InlineTextButton,
    SocialLoginButton,
    IconClose,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Modal,
    TermsOfService,
} from '../../components';
import { ConfirmSignupForm, LoginForm, SignupForm, CompleteProfileForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import {
    login,
    authenticationInProgress,
    signup,
    signupWithIdp,
    uploadImage,
    uploadTempImage,
    updateProfile,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './AuthenticationPage.module.css';
import { FacebookLogo, GoogleLogo } from './socialLoginLogos';

export class AuthenticationPageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tosModalOpen: false,
            authError: Cookies.get('st-autherror')
                ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
                : null,
            authInfo: Cookies.get('st-authinfo')
                ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
                : null,
            tempImage: null,
        };
    }

    componentDidMount() {
        // Remove the autherror cookie once the content is saved to state
        // because we don't want to show the error message e.g. after page refresh
        Cookies.remove('st-autherror');
    }

    render() {
        const {
            authInProgress,
            currentUser,
            intl,
            isAuthenticated,
            location,
            loginError,
            scrollingDisabled,
            signupError,
            submitLogin,
            submitSignup,
            confirmError,
            submitSingupWithIdp,
            tab,
            sendVerificationEmailInProgress,
            sendVerificationEmailError,
            onResendVerificationEmail,
            onManageDisableScrolling,
            history,
            onImageUpload,
            onTempImageUpload,
            uploadImageError,
            uploadTempImageError,
            uploadInProgress,
            uploadTempInProgress,
            image,
            temporalImage,
        } = this.props;

        const onImageUploadHandler = (values, fn) => {
          const { id, imageId, file } = values;
          if (file) {
            fn({ id, imageId, file });
          }
        };

        const isConfirm = tab === 'confirm';
        const isLogin = tab === 'login';
        const isCompleteSignup = tab === 'completeSignup';
        const locationFrom =
            location.state && location.state.from ? location.state.from : null;
        const authinfoFrom =
            this.state.authInfo && this.state.authInfo.from
                ? this.state.authInfo.from
                : null;
        const from = locationFrom
            ? locationFrom
            : authinfoFrom
                ? authinfoFrom
                : null;

        const user = ensureCurrentUser(currentUser);
        const currentUserLoaded = !!user.id;
        const profileImageId = user.profileImage ? user.profileImage.id : null;
        const profileImage = image || { imageId: profileImageId };

        // We only want to show the email verification dialog in the signup
        // tab if the user isn't being redirected somewhere else
        // (i.e. `from` is present). We must also check the `emailVerified`
        // flag only when the current user is fully loaded.
        const showEmailVerification =
            !isLogin && currentUserLoaded && !user.attributes.emailVerified && user?.profileImage;

        const showCompleteSignup =
            !isLogin && currentUserLoaded && !user?.profileImage;

            // Already authenticated, redirect away from auth page
        if (isAuthenticated && from && !isCompleteSignup && !showCompleteSignup) {
            return <Redirect to={from} />;
        } else if (
            isAuthenticated &&
            currentUserLoaded &&
            !showEmailVerification &&
            !isCompleteSignup &&
            !showCompleteSignup
        ) {
            return <NamedRedirect name="LandingPage" />;
        }

        const loginErrorMessage = (
            <div className={css.error}>
                <FormattedMessage id="AuthenticationPage.loginFailed" />
            </div>
        );

        const signupErrorMessage = (
            <div className={css.error}>
                {isSignupEmailTakenError(signupError) ? (
                    <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                ) : (
                    <FormattedMessage id="AuthenticationPage.signupFailed" />
                )}
            </div>
        );

        const confirmErrorMessage = confirmError ? (
            <div className={css.error}>
                {isSignupEmailTakenError(confirmError) ? (
                    <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                ) : (
                    <FormattedMessage id="AuthenticationPage.signupFailed" />
                )}
            </div>
        ) : null;

        // eslint-disable-next-line no-confusing-arrow
        const errorMessage = (error, message) => (error ? message : null);
        const loginOrSignupError = isLogin
            ? errorMessage(loginError, loginErrorMessage)
            : errorMessage(signupError, signupErrorMessage);

        const fromState = { state: from ? { from } : null };

        const tabs = [
            {
                text: (
                    <h1 className={css.tab}>
                        <FormattedMessage id="AuthenticationPage.signupLinkText" />
                    </h1>
                ),
                selected: !isLogin,
                linkProps: {
                    name: 'SignupPage',
                    to: fromState,
                },
            },
            {
                text: (
                    <h1 className={css.tab}>
                        <FormattedMessage id="AuthenticationPage.loginLinkText" />
                    </h1>
                ),
                selected: isLogin,
                linkProps: {
                    name: 'LoginPage',
                    to: fromState,
                },
            },
        ];

        const handleSubmitSignup = values => {
            const {
                fname,
                lname,
                state,
                city,
                country,
                zipCode,
                phoneNumber,
                bio,
                ...rest
            } = values;
            // const params = { firstName: fname.trim(), lastName: lname.trim(), city: city.trim(), state: state.trim(), phoneNumber: phoneNumber ? `+1${phoneNumber.trim()}` : '', ...rest };
            // submitSignup(params);

            var playerType = [];
            if (values.Player) {
                playerType.push('player');
            }
            if (values.Coach) {
                playerType.push('coach');
            }
            if (values.CourtOwner) {
                playerType.push('court_owner');
            }

            if (isCompleteSignup || showCompleteSignup) {
                const profile = {
                    bio: bio,
                    image: image,
                    publicData: {
                        zipCode: zipCode.trim(),
                        city: city.trim(),
                        state: state.trim(),
                        country: country.trim(),
                        phoneNumber: phoneNumber
                            ? `+1${phoneNumber.trim()}`
                            : '',
                        playerType: playerType,
                    },
                };
                this.props.updateProfile(profile, true);

            } else {
                const params = {
                    firstName: fname.trim(),
                    lastName: lname.trim(),
                    ...rest,
                };
                submitSignup(params);
            }
        };

        const handleSubmitConfirm = values => {
            const {
                idpToken,
                email,
                firstName,
                lastName,
                idpId,
            } = this.state.authInfo;
            const {
                email: newEmail,
                firstName: newFirstName,
                lastName: newLastName,
                ...rest
            } = values;

            // Pass email, fistName or lastName to Flex API only if user has edited them
            // sand they can't be fetched directly from idp provider (e.g. Facebook)

            const authParams = {
                ...(newEmail !== email && { email: newEmail }),
                ...(newFirstName !== firstName && { firstName: newFirstName }),
                ...(newLastName !== lastName && { lastName: newLastName }),
            };

            // If the confirm form has any additional values, pass them forward as user's protected data
            const protectedData = !isEmpty(rest) ? { ...rest } : null;

            submitSingupWithIdp({
                idpToken,
                idpId,
                ...authParams,
                ...(!!protectedData && { protectedData }),
            });
        };

        const getDefaultRoutes = () => {
            const routes = routeConfiguration();
            const baseUrl = apiBaseUrl();

            // Route where the user should be returned after authentication
            // This is used e.g. with EditListingPage and ListingPage
            const fromParam = from ? `from=${from}` : '';

            // Default route where user is returned after successfull authentication
            const defaultReturn = pathByRouteName('LandingPage', routes);
            const defaultReturnParam = defaultReturn
                ? `&defaultReturn=${defaultReturn}`
                : '';

            // Route for confirming user data before creating a new user
            const defaultConfirm = pathByRouteName('ConfirmPage', routes);
            const defaultConfirmParam = defaultConfirm
                ? `&defaultConfirm=${defaultConfirm}`
                : '';

            return {
                baseUrl,
                fromParam,
                defaultReturnParam,
                defaultConfirmParam,
            };
        };
        const authWithFacebook = () => {
            const defaultRoutes = getDefaultRoutes();
            const {
                baseUrl,
                fromParam,
                defaultReturnParam,
                defaultConfirmParam,
            } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/facebook?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
        };

        const authWithGoogle = () => {
            const defaultRoutes = getDefaultRoutes();
            const {
                baseUrl,
                fromParam,
                defaultReturnParam,
                defaultConfirmParam,
            } = defaultRoutes;
            window.location.href = `${baseUrl}/api/auth/google?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
        };

        const idp = this.state.authInfo
            ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
            : null;

        const isNewUser = !showEmailVerification && !user?.attributes?.profile?.publicData?.zipCode

        // form content
        // Form for confirming information frm IdP (e.g. Facebook)
        // before new user is created to Flex
        const confirmForm = (
            <div className={css.content}>
                <h1 className={css.signupWithIdpTitle}>
                    <FormattedMessage
                        id="AuthenticationPage.confirmSignupWithIdpTitle"
                        values={{ idp }}
                    />
                </h1>

                <p className={css.confirmInfoText}>
                    <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
                </p>
                {confirmErrorMessage}
                <ConfirmSignupForm
                    className={css.form}
                    onSubmit={handleSubmitConfirm}
                    inProgress={authInProgress}
                    onOpenTermsOfService={() =>
                        window.open('/terms/', '_blank')
                    }
                    authInfo={this.state.authInfo}
                    idp={idp}
                />
            </div>
        );

        // Social login buttons
        const showFacebookLogin =
            !!process.env.REACT_APP_FACEBOOK_APP_ID && !isCompleteSignup;
        const showGoogleLogin =
            !!process.env.REACT_APP_GOOGLE_CLIENT_ID && !isCompleteSignup;
        const showSocialLogins = showFacebookLogin || showGoogleLogin;

        const facebookButtonText = isLogin ? (
            <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
        ) : (
            <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
        );

        const googleButtonText = isLogin ? (
            <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
        ) : (
            <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
        );
        const socialLoginButtonsMaybe = showSocialLogins ? (
            <div className={css.idpButtons}>
                <div className={css.socialButtonsOr}>
                </div>

                {showFacebookLogin ? (
                    <div className={css.socialButtonWrapper}>
                        <SocialLoginButton onClick={() => authWithFacebook()}>
                            <span className={css.buttonIcon}>
                                {FacebookLogo}
                            </span>
                            {facebookButtonText}
                        </SocialLoginButton>
                    </div>
                ) : null}

                {showGoogleLogin ? (
                    <div className={css.socialButtonWrapper}>
                        <SocialLoginButton onClick={() => authWithGoogle()}>
                            <span className={css.buttonIcon}>{GoogleLogo}</span>
                            {googleButtonText}
                        </SocialLoginButton>
                    </div>
                ) : null}
            </div>
        ) : null;

        // Delte country code from phone number
        const phoneNumber = user.attributes?.profile?.publicData?.phoneNumber;
        const phoneNumberWithoutCountryCode = phoneNumber
            ? phoneNumber.replace('+1', '')
            : null;

        const processTempImage = (value) => {
            onTempImageUpload(value);
            this.setState({ tempImage: value });
        }

        // form content
        // Tabs for SignupForm and LoginForm
        const authenticationForms = (
            <div className={css.content}>

                {isLogin ? (
                    <LoginForm
                        className={css.loginForm}
                        onSubmit={submitLogin}
                        inProgress={authInProgress}
                        socialLoginButtonsMaybe={socialLoginButtonsMaybe}
                    />
                ) : showCompleteSignup ? (
                    <CompleteProfileForm
                        className={css.signupForm}
                        onSubmit={handleSubmitSignup}
                        initialValues={{
                            bio: user.attributes?.profile?.bio,
                            country: user.attributes?.profile?.publicData?.country || 'United States',
                            city: user.attributes?.profile?.publicData?.city,
                            state: user.attributes?.profile?.publicData?.state,
                            phoneNumber: phoneNumberWithoutCountryCode,
                            zipCode: user.attributes?.profile?.publicData?.zipCode,
                            ...(user.attributes?.profile?.publicData?.playerType?.includes("player") && { "Player": [true] }),
                            ...(user.attributes?.profile?.publicData?.playerType?.includes("coach") && { "Coach": [true] }),
                            ...(user.attributes?.profile?.publicData?.playerType?.includes("court_owner") && { "CourtOwner": [true] }),
                        }}
                        inProgress={authInProgress}
                        onOpenTermsOfService={() =>
                            window.open('/terms/', '_blank')
                        }
                        isCompleteSignup={showCompleteSignup ? true : isCompleteSignup}
                        // onImageUpload={onImageUpload}
                        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
                        onManageDisableScrolling={onManageDisableScrolling}
                        uploadImageError={uploadImageError}
                        uploadTempImageError={uploadTempImageError}
                        uploadInProgress={uploadInProgress}
                        uploadTempInProgress={uploadTempInProgress}
                        profileImage={profileImage}
                        updateProfileError={null}
                        user={user}
                        image={image}
                        tempImage={this.state.tempImage}
                        temporalImage={temporalImage}
                        setTempImage={(value) => {
                            processTempImage(value);
                        }}
                        isNewUser={isNewUser}
                    />
                ) : (
                    <SignupForm
                        className={css.signupForm}
                        onSubmit={handleSubmitSignup}
                        inProgress={authInProgress}
                        onOpenTermsOfService={() =>
                            window.open('/terms/', '_blank')
                        }
                        isCompleteSignup={showCompleteSignup ? true : isCompleteSignup}
                        socialLoginButtonsMaybe={socialLoginButtonsMaybe}
                    />
                )}

                {loginOrSignupError}

            </div>
        );

        // include in layoutMainWrapper?
        const formContent = isConfirm ? confirmForm : authenticationForms;

        const name = user.attributes.profile.firstName;
        const email = (
            <span className={css.email}>{user.attributes.email}</span>
        );

        const resendEmailLink = (
            <InlineTextButton
                rootClassName={css.modalHelperLink}
                onClick={onResendVerificationEmail}>
                <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
            </InlineTextButton>
        );
        const fixEmailLink = (
            <NamedLink
                className={css.modalHelperLink}
                name="ContactDetailsPage">
                <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
            </NamedLink>
        );

        const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
        )
            ? 'AuthenticationPage.resendFailedTooManyRequests'
            : 'AuthenticationPage.resendFailed';
        const resendErrorMessage = sendVerificationEmailError ? (
            <p className={css.error}>
                <FormattedMessage id={resendErrorTranslationId} />
            </p>
        ) : null;

        const userType = user.attributes?.profile?.publicData?.playerType;
        const isCourtOwner = userType?.includes('court_owner');

        const emailVerificationContent = (
            <div className={css.content}>
                <NamedLink
                    className={css.verifyClose}
                    name={isCourtOwner ? "NewListingPage" : "ProfileSettingsPage"}>
                    <span className={css.closeText}>
                        <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
                    </span>
                    <IconClose rootClassName={css.closeIcon} />
                </NamedLink>

                <IconEmailSent className={css.modalIcon} />
                <h1 className={css.modalTitle}>
                    <FormattedMessage
                        id="AuthenticationPage.verifyEmailTitle"
                        values={{ name }}
                    />
                </h1>
                <p className={css.modalMessage}>
                    <FormattedMessage
                        id="AuthenticationPage.verifyEmailText"
                        values={{ email }}
                    />
                </p>
                {resendErrorMessage}

                <div className={css.bottomWrapper}>
                    <p className={css.modalHelperText}>
                        {sendVerificationEmailInProgress ? (
                            <FormattedMessage id="AuthenticationPage.sendingEmail" />
                        ) : (
                            <FormattedMessage
                                id="AuthenticationPage.resendEmail"
                                values={{ resendEmailLink }}
                            />
                        )}
                    </p>
                    <p className={css.modalHelperText}>
                        <FormattedMessage
                            id="AuthenticationPage.fixEmail"
                            values={{ fixEmailLink }}
                        />
                    </p>
                </div>
            </div>
        );

        const showAuthTitle = (
            <div className={css.authContainer}>
                <div className={css.authTitle}>
                    <h1 className={css.authTitle}>
                        {!isLogin && !showCompleteSignup ? (
                            <FormattedMessage id="AuthenticationPage.signupTitle" />
                        ) : showCompleteSignup && !isNewUser ? (
                            <FormattedMessage id="AuthenticationPage.completeSecondSignupTitle" values={{name: user?.attributes?.profile?.firstName}}/>
                        ) : showCompleteSignup ? (
                            <FormattedMessage id="AuthenticationPage.completeSignupTitle" values={{name: user?.attributes?.profile?.firstName}}/>
                        ) : (
                            <FormattedMessage id="AuthenticationPage.authTitle" />
                        )}
                    </h1>
                </div>
            </div>
        );

        const siteTitle = config.siteTitle;
        const schemaTitle = isLogin
            ? intl.formatMessage(
                { id: 'AuthenticationPage.schemaTitleLogin' },
                { siteTitle }
            )
            : intl.formatMessage(
                { id: 'AuthenticationPage.schemaTitleSignup' },
                { siteTitle }
            );

        const topbarClasses = classNames({
            [css.hideOnMobile]: showEmailVerification,
        });

        return (
            <Page
                title={schemaTitle}
                scrollingDisabled={scrollingDisabled}
                schema={{
                    '@context': 'http://schema.org',
                    '@type': 'WebPage',
                    name: schemaTitle,
                }}>
                <LayoutSingleColumn>
                    <LayoutWrapperTopbar>
                        <TopbarContainer className={topbarClasses} />
                    </LayoutWrapperTopbar>

                    <LayoutWrapperMain className={css.layoutWrapperMain}>
                        <div className={classNames(css.root, {
                            [css.isSignup]: !showCompleteSignup && !isLogin,
                            [css.showCompleteSignup]: showCompleteSignup,
                        })}>
                            {showAuthTitle}
                            {showEmailVerification
                                ? emailVerificationContent
                                : formContent}
                        </div>

                        <Modal
                            id="AuthenticationPage.tos"
                            isOpen={this.state.tosModalOpen}
                            onClose={() =>
                                this.setState({ tosModalOpen: false })
                            }
                            usePortal
                            onManageDisableScrolling={onManageDisableScrolling}>
                            <div className={css.termsWrapper}>
                                <h2 className={css.termsHeading}>
                                    <FormattedMessage id="AuthenticationPage.termsHeading" />
                                </h2>
                                <TermsOfService />
                            </div>
                        </Modal>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }
}

AuthenticationPageComponent.defaultProps = {
    currentUser: null,
    loginError: null,
    signupError: null,
    confirmError: null,
    tab: 'signup',
    sendVerificationEmailError: null,
    showSocialLoginsForTests: false,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
    authInProgress: bool.isRequired,
    currentUser: propTypes.currentUser,
    isAuthenticated: bool.isRequired,
    loginError: propTypes.error,
    scrollingDisabled: bool.isRequired,
    signupError: propTypes.error,
    confirmError: propTypes.error,

    submitLogin: func.isRequired,
    submitSignup: func.isRequired,
    tab: oneOf(['login', 'signup', 'confirm']),

    sendVerificationEmailInProgress: bool.isRequired,
    sendVerificationEmailError: propTypes.error,
    onResendVerificationEmail: func.isRequired,
    onManageDisableScrolling: func.isRequired,

    // from withRouter
    location: shape({ state: object }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const {
        isAuthenticated,
        loginError,
        signupError,
        confirmError,
        uploadImageError,
        uploadTempImageError,
        uploadInProgress,
        uploadTempInProgress,
        image,
        tempImage
    } = state.Auth;
    const {
        currentUser,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
    } = state.user;
    return {
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        loginError,
        scrollingDisabled: isScrollingDisabled(state),
        signupError,
        confirmError,
        sendVerificationEmailInProgress,
        sendVerificationEmailError,
        uploadImageError,
        uploadTempImageError,
        uploadInProgress,
        uploadTempInProgress,
        image,
        temporalImage: tempImage,
    };
};

const mapDispatchToProps = dispatch => ({
    submitLogin: ({ email, password }) => dispatch(login(email, password)),
    submitSignup: params => dispatch(signup(params)),
    submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
    updateProfile: params => dispatch(updateProfile(params)),
    onImageUpload: data => dispatch(uploadImage(data)),
    onTempImageUpload: data => dispatch(uploadTempImage(data)),
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
